import { render, staticRenderFns } from "./BoxWithShadow.vue?vue&type=template&id=69277c6f&scoped=true&"
import script from "./BoxWithShadow.vue?vue&type=script&lang=js&"
export * from "./BoxWithShadow.vue?vue&type=script&lang=js&"
import style0 from "./BoxWithShadow.vue?vue&type=style&index=0&id=69277c6f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69277c6f",
  null
  
)

export default component.exports